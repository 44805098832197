<template>
  <div class="forgot">
    <span class="forgot__close" @click="closeForgotDialog">
      <atomic-icon id="close" class="forgot__close--icon" />
    </span>

    <div class="forgot-header">
      <div class="forgot-header__wrap" @click="goToBack">
        <atomic-icon v-if="isMobile" id="arrow_previous" />

        <div class="forgot-header__title">
          {{ getContent(currentLocaleData, defaultLocaleData, 'resetPassword') }}
        </div>
      </div>

      <div class="forgot-header__text">
        {{ getContent(currentLocaleData, defaultLocaleData, 'enterContactVerify') }}
      </div>
    </div>

    <authorization-tabs v-model:tabs="tabs">
      <template #email>
        <form-input-text
          v-model:value="forgotFormData.email"
          :hint="setError('email')"
          type="email"
          is-required
          label=""
          name="email"
          :placeholder="getContent(currentLocaleData, defaultLocaleData, 'enterEmailAddress')"
          @blur="v$.email?.$touch()"
          @focus="onFocus('email')"
          @submit="sendEmail"
        />
      </template>
    </authorization-tabs>

    <div class="forgot__buttons">
      <button-base v-if="!isMobile" type="gray" size="md" @click="openModalSync(ModalName.SIGN_IN)">
        {{ getContent(currentLocaleData, defaultLocaleData, 'back') }}
      </button-base>

      <button-base type="primary" size="md" :isProcessing="isLockedAsyncButton" @click="sendEmail">
        {{ getContent(currentLocaleData, defaultLocaleData, 'send') }}
      </button-base>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ModalName } from '@skeleton/consts/modals';

  import type { IModalsContent } from '~/types';

  defineProps<{
    currentLocaleData: Maybe<IModalsContent['authorization']>;
    defaultLocaleData: Maybe<IModalsContent['authorization']>;
  }>();

  const { canAuthorizeWithEmail, canAuthorizeWithPhone } = storeToRefs(useAuthorization());
  const { closeModal, openModal, openModalSync } = useModalStore();
  const { showAlert } = useLayoutStore();
  const globalStore = useGlobalStore();
  const { alertsData, defaultLocaleAlertsData } = storeToRefs(globalStore);
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const tabs = ref([
    {
      title: 'Email',
      isActive: false,
      key: 'email',
      icon: 'message',
      canShow: true,
    },
    // {
    //   title: 'Phone',
    //   isActive: false,
    //   key: 'phone',
    //   icon: 'phone',
    //   canShow: false,
    // },
  ]);

  const forgotFormData = reactive({ email: '' });
  const { getFormRules, getContent } = useProjectMethods();
  const forgotRules = {
    email: [{ rule: 'required' }, { rule: 'email' }],
  };
  const forgotFormRules = getFormRules(forgotRules);
  const { v$, onFocus, setError } = useFormValidation(forgotFormRules, forgotFormData);

  const { forgotProfilePassword } = useCoreProfileApi();
  const isLockedAsyncButton = ref<boolean>(false);

  const goToBack = () => {
    if (isMobile.value) {
      openModalSync(ModalName.SIGN_IN);
    }
  };

  const closeForgotDialog = (): void => {
    closeModal(ModalName.FORGOT_PASS);
  };

  const openResetEmailSendDialog = (): void => {
    openModal(ModalName.RESET_EMAIL_SEND, {
      props: { email: forgotFormData.email },
    });
  };

  const sendEmail = async (): Promise<void> => {
    if (v$.value.$invalid) return;

    v$.value.$reset();
    const validFormData = await v$.value.$validate();
    if (!validFormData) return;

    try {
      isLockedAsyncButton.value = true;
      await forgotProfilePassword(forgotFormData);
      closeForgotDialog();
      openResetEmailSendDialog();
    } catch (error: any) {
      if (error.response?.status === 422) {
        closeForgotDialog();
        openResetEmailSendDialog();
      } else {
        showAlert(alertsData.value?.global?.somethingWrong || defaultLocaleAlertsData.value?.global?.somethingWrong);
      }
    } finally {
      isLockedAsyncButton.value = false;
    }
  };

  const toggleTab = (tabKey: 'email' | 'phone', canShow: boolean) => {
    const foundTab = tabs.value.find(tab => tab.key === tabKey);
    if (foundTab) foundTab.canShow = canShow;
  };

  watchEffect(() => {
    if (canAuthorizeWithEmail.value) toggleTab('email', true);
    else toggleTab('email', false);

    if (canAuthorizeWithPhone.value) toggleTab('phone', true);
    else toggleTab('phone', false);
  });
</script>

<style src="~/assets/styles/components/authorization/forgot.scss" lang="scss" />
